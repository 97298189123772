import React from "react";

export default ({ title, children }) => {
  return (
    <div className="card mb-4">
      <div className="card-header">
        <h3>{title}</h3>
      </div>
      <div className="card-body">
        {children}
      </div>
    </div>
  );
};
