import React, { useEffect, useState, useCallback } from "react";
import { Steps, Spinner, ThreeDModelViewer } from "../components";
import { useParams } from "react-router";
import FrostyAuctionAbi from "../contracts/FrostyAuction.json";
import FrostyGarmentNftAbi from "../contracts/FrostyGarmentNFT.json";

const AuctionDetails = (props) => {
  const [initCompleted, setInitCompleted] = useState(false);
  const [accounts, setAccounts] = useState(props.accounts);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [frostyAuctionContract, setFrostyAuctionContract] = useState();
  const [auctionInfo, setAuctionInfo] = useState();
  const [nftInfo, setNftInfo] = useState();
  const [minimumBidIncrement, setMinimumBidIncrement] = useState();

  const [highestBidderInfo, setHighestBidderInfo] = useState();
  const [minimumBid, setMinimumBid] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [auctionClosed, setAuctionClosed] = useState(false);
  const [salePrice, setSalePrice] = useState();
  const [bidsTransactions, setBidsTransactions] = useState();
  const [amount, setAmount] = useState(0);
  const { id } = useParams();

  /* Update all */
  const updateAll = useCallback(async () => {
    /* Get Highest Bidder and Minimum new Bid */
    async function getNewMinimumBid() {
      const _highestBidder = await frostyAuctionContract.methods
        .getHighestBidder(id)
        .call();
      const _highestBidderInfo = {
        bid: _highestBidder._bid,
        bidder: beautifyAddress(_highestBidder._bidder),
        lastBidTime: beautifyDate(_highestBidder._lastBidTime),
      };
      setHighestBidderInfo(_highestBidderInfo);

      let _highestBid = toDecimal(_highestBidder._bid);
      let _reservePrice = toDecimal(auctionInfo.reservePrice);
      let _minimumBid = _reservePrice;
      if (_highestBid >= _reservePrice) {
        _minimumBid = _highestBid + minimumBidIncrement;
      }

      // The bid amount is taking sometimes some decimal numbers coming from the contract ex: 1.000000000001
      // We cannot remove them, nor make the minimum bid smaller. Which means we must round to 2 decimal numbers.
      if (countDecimals(_minimumBid) > 2) {
        _minimumBid = Math.ceil((_minimumBid + Number.EPSILON) * 100) / 100;
      }

      setMinimumBid(_minimumBid);
    }

    /* Get all bids transactions */
    const getBidsTransactions = async () => {
      // Connect to the explorer API to get all transaction done on the auction address
      const requestUrl =
        process.env.REACT_APP_API_BASE_URL +
        "/api?module=account&action=txlist&address=" +
        process.env.REACT_APP_AUCTION +
        "&startblock=0&endblock=99999999&sort=desc&apikey=" +
        process.env.REACT_APP_API_TOKEN;

      // The NFT id is passed in the transcation as coded hexadecimal number at the end of input column.
      // We need to compare it to the id we have after that we convert that id to hexadecimal
      const _idAsInt = parseInt(id);
      let _idAsHex = _idAsInt.toString(16);
      if (_idAsHex.length < 4) {
        _idAsHex = "000" + _idAsHex;
      }

      // We filter the transcations to get the ones with value bigger than 0, and contains the NFT id
      const response = await fetch(requestUrl)
        .then((res) => res.json())
        .then((data) => {
          const transactions =
            data && data.result && Array.isArray(data.result)
              ? data.result
              : [];

          return transactions.filter(
            (item) => item.input.endsWith(_idAsHex) && item.value > 0
          );
        });

      setBidsTransactions(response);
    };

    if (
      accounts &&
      accounts.length > 0 &&
      frostyAuctionContract &&
      auctionInfo &&
      minimumBidIncrement &&
      id
    ) {
      await getNewMinimumBid();
      await getBidsTransactions();
    } else {
      setError("Please connect your Metamask, and refresh the page.");
    }
  }, [accounts, frostyAuctionContract, auctionInfo, minimumBidIncrement, id]);

  /* Beautify Amount (as string) */
  const beautifyAmount = (a) => {
    let _decimalNum = a / 10 ** 18;
    let _re = new RegExp("^-?\\d+(?:.\\d{0," + (4 || -1) + "})?"); // eslint-disable-line
    return _decimalNum.toString().match(_re)[0];
  };

  /* Divide by 10 power 18 */
  const toDecimal = (a) => {
    let _decimalNum = a / 10 ** 18;
    return _decimalNum;
  };

  /* Beautify Date */
  const beautifyDate = (d) => {
    let _date = new Date(d * 1000);
    let _dateAsString = _date.toUTCString();
    return _dateAsString;
  };

  /* Beautify Address */
  const beautifyAddress = (a) => {
    let _address =
      a.substring(0, 4) + "...." + a.substring(a.length - 4, a.length);
    return _address;
  };

  /* Time to end auction */
  const calculateTimeLeft = () => {
    if (auctionInfo) {
      let dateNow = Date.now();
      let endDate = auctionInfo.endTime * 1000;
      let _difference = endDate - dateNow;
      if (_difference > 0) {
        let _timeLeft = {
          days: Math.floor(_difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((_difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((_difference / 1000 / 60) % 60),
          seconds: Math.floor((_difference / 1000) % 60),
        };
        setTimeLeft(_timeLeft);
      } else {
        setAuctionClosed(true);
      }
    }
  };

  /* Count the number of decimals */
  const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
  };

  /* init */
  const init = useCallback(async () => {
    if (initCompleted || loading || !props.web3 || !props.accounts) {
      return;
    }

    setLoading(true);

    /* Init Auction Contract */
    const _frostyAuctionContract = new props.web3.eth.Contract(
      FrostyAuctionAbi.abi,
      process.env.REACT_APP_AUCTION
    );

    /* Init NFT Contract */
    const _frostyGarmentNftContract = new props.web3.eth.Contract(
      FrostyGarmentNftAbi.abi,
      process.env.REACT_APP_GARMENT_NFT
    );

    /* Get Auction Info */
    const _auction = await _frostyAuctionContract.methods.getAuction(id).call();
    const _auctionInfo = {
      startTime: _auction._startTime,
      endTime: _auction._endTime,
      reservePrice: parseInt(_auction._reservePrice),
      resulted: _auction._resulted,
    };

    /* Get NFT Info */
    const _nftUri = await _frostyGarmentNftContract.methods.tokenURI(id).call();
    const _nftInfo = await fetch(_nftUri)
      .then((res) => res.json())
      .then((data) => {
        return data;
      });

    /* Get minimum bid increment */
    let _minimumBidIncrement = await _frostyAuctionContract.methods
      .minBidIncrement()
      .call();
    _minimumBidIncrement = toDecimal(_minimumBidIncrement);

    /* Get sale Price */
    const _salePrice = await _frostyGarmentNftContract.methods
      .primarySalePrice(id)
      .call();

    setFrostyAuctionContract(_frostyAuctionContract);
    setAuctionInfo(_auctionInfo);
    setNftInfo(_nftInfo);
    setMinimumBidIncrement(_minimumBidIncrement);
    setSalePrice(_salePrice);
    setInitCompleted(true);
    setLoading(false);

    // Saving value in local storage to avoid disconnecting on refresh
    window.localStorage.setItem("account", "connected");
  }, [initCompleted, loading, props.web3, props.accounts, id]);

  init();

  /* useEffect */
  useEffect(() => {
    // Needed to stop memory leak
    let mounted = true;

    /* CountDown */
    if (!auctionClosed) {
      setTimeout(() => {
        if (mounted) {
          calculateTimeLeft();
        }
      }, 1000);
    }

    return () => (mounted = false);
  });

  useEffect(() => {
    setAccounts(props.accounts);

    // If account is saved in local storage, trigger click on the connect button
    if (window.localStorage.getItem("account") !== null) {
      props.onConnect();
    }

    updateAll();
  }, [props, props.accounts, updateAll]);

  /* CountDown */
  const CountDown = () => {
    if (!auctionClosed && !timeLeft) {
      return (
        <div className="float-right">
          <Spinner size={48} />
        </div>
      );
    } else {
      if (auctionClosed) {
        return (
          <div className="alert alert-info text-left d-inline-block">
            This auction is over
          </div>
        );
      } else {
        if (timeLeft) {
          return (
            <div className="small-timer">
              <div>
                {timeLeft.days}
                <small>D</small>
              </div>
              <div>
                {timeLeft.hours}
                <small>H</small>
              </div>
              <div>
                {timeLeft.minutes}
                <small>M</small>
              </div>
              <div>
                {timeLeft.seconds}
                <small>S</small>
              </div>
            </div>
          );
        }
      }
    }
  };

  /* Bid */
  async function bid() {
    setLoading(true);

    let _amount = amount * 10 ** 18;
    let _minimumBid = minimumBid * 10 ** 18;

    if (_amount < 0) {
      alert("Please enter a positive number");
      setLoading(false);
      return;
    }

    if (_amount >= 0 && _amount < _minimumBid) {
      alert(`The minimum bid is currently ${minimumBid} BNB`);
      setLoading(false);
      return;
    }

    if (_amount >= _minimumBid) {
      if (window.confirm(`Are you sure you want to bid with ${amount} BNB`)) {
        try {
          const sender = accounts[0];
          await frostyAuctionContract.methods
            .placeBid(id)
            .send({ from: sender, value: _amount });
          alert("You have placed your bid successfully");
          await updateAll();
        } catch (err) {
          setError(err);
          console.error(err);
        }
      }
    }

    setLoading(false);
    return;
  }

  /* Bids List */
  const BidsList = () => {
    return (
      <div className="table-responsive-md">
        <table className="table table-sm table-striped table-bordered table-hover table-light">
          <thead className="thead-light">
            <tr>
              <th>Time</th>
              <th>Address</th>
              <th>Bid</th>
            </tr>
          </thead>
          <tbody>
            {bidsTransactions.map((transaction, index) => {
              return (
                <tr
                  key={index}
                  className={`${
                    transaction.from.toUpperCase() === accounts[0].toUpperCase()
                      ? "active"
                      : ""
                  }`}
                >
                  <td>{beautifyDate(transaction.timeStamp)}</td>
                  <td>
                    <div title={transaction.from}>
                      {beautifyAddress(transaction.from)}
                    </div>
                  </td>
                  <td>{beautifyAmount(transaction.value)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="relative bg-white py-5">
      <div className="container">
        {(!accounts || accounts.length === 0) && (
          <div className="text-center">
            <button className="btn btn-primary mb-5" onClick={props.onConnect}>
              {loading && <Spinner color="white" size={24} />}
              {!loading && (error !== "" ? error : "Connect Metamask Wallet")}
            </button>

            <Steps />
          </div>
        )}

        {accounts && accounts.length > 0 && (
          <>
            {nftInfo && auctionInfo && highestBidderInfo && (
              <div className="nft-details">
                <div className="row justify-content-between mb-4">
                  <div className="col-md-7 mb-3 mb-md-0">
                    <h1 className="nft-name mb-0">{nftInfo.name}</h1>
                  </div>
                  <div className="col-md-5 text-right">
                    <CountDown />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5 mb-4">
                    <div className="photo">
                      {!auctionInfo.resulted && (
                        <span className="ribbon">
                          <span className="bg-navy">
                            Highest bid
                            <br />
                            {beautifyAmount(highestBidderInfo.bid)} BNB
                          </span>
                        </span>
                      )}

                      {auctionInfo.resulted && salePrice > 0 && (
                        <span className="ribbon">
                          <span className="bg-secondary">
                            Sold for
                            <br />
                            {beautifyAmount(salePrice)} BNB
                          </span>
                        </span>
                      )}

                      {auctionInfo.resulted && salePrice === 0 && (
                        <span className="ribbon">
                          <span className="bg-secondary">Not Sold</span>
                        </span>
                      )}

                      {!nftInfo.glb_url ?
                        <img src={nftInfo.image} alt={nftInfo.name} />
                      :
                       <div className="threed-holder">
                          <React.StrictMode>
                            <ThreeDModelViewer glbFile={nftInfo.glb_url} />
                          </React.StrictMode>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="description mb-4">
                      {nftInfo.description}
                    </div>
                    <div className="form-row nft-attributes mb-4">
                      {nftInfo.attributes.map((attribute, index) => (                        
                        <div className="col-md-4 mb-2" key={index}>
                          <div className="attribute">
                            <span>{attribute.trait_type}</span> :{" "}
                            <span>
                              {( attribute.display_type && attribute.display_type === 'date' ? 
                                (beautifyDate(attribute.value)) 
                                : 
                                (attribute.value)
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>

                    {!auctionClosed && (
                      <>
                        <div className="mb-4 action-holder">
                          <div className="form-row align-items-center">
                            <div className="col-md-3 col-6">
                              <small>Reserve Price</small>
                              <br />
                              <strong className="reserve-price">
                                {beautifyAmount(auctionInfo.reservePrice)} BNB
                              </strong>
                            </div>

                            <div className="col-md-3 col-6">
                              <small>Minimum Bid</small>
                              <br />
                              <strong className="last-bid">
                                {minimumBid} BNB
                              </strong>
                            </div>

                            <div className="col-md-6 mt-md-0 mt-3">
                              <div className="bidding-form">
                                <div className="row no-gutters">
                                  <div className="col-sm-5">
                                    <input
                                      type="number"
                                      maxLength="18"
                                      min={minimumBid}
                                      max="2"
                                      step="0.1"
                                      value={amount}
                                      onChange={(e) =>
                                        setAmount(e.target.value)
                                      }
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-sm-7">
                                    <button className="btn btn-block btn-primary">
                                      {loading ? (
                                        <Spinner size={24} color="white" />
                                      ) : (
                                        <span
                                          className="d-block"
                                          onClick={() => bid()}
                                        >
                                          Bid
                                        </span>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {auctionClosed && !auctionInfo.resulted && (
                      <div className="alert alert-danger">
                        The auction has ended and the NFT will be distributed
                        soon
                      </div>
                    )}

                    {bidsTransactions && bidsTransactions.length > 0 ? (
                      <BidsList />
                    ) : (
                      <h5>No bids have been made</h5>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>      
    </div>
  );
};

export default AuctionDetails;
