import React from "react";
import ParticlesAnimation from "../components/ParticlesAnimation";
import RoadmapTimeline from "../components/RoadmapTimeline";
import styled from "styled-components";
import {
  BounceLeftAnimation,
  BounceRightAnimation,
  BounceUpAnimation,
  FadeAnimation,
} from "../styles/ScrollAnimation.styles";

const LandingPage = () => {
  return (
    <div className="relative overflow-hidden">
      <div className="main-banner">
        <ParticlesAnimation />
        <div className="block">
          <FadeAnimation>
            <h1 className="text-center mb-2">FROSTY</h1>
            <h2 className="m-0 text-uppercase">The Digital Art Engine</h2>
          </FadeAnimation>
        </div>
      </div>

      <div className="bg-white">
        <div className="container py-md-5 py-4">
          <div className="row justify-content-md-center">
            <div className="col-md-8">
              <BounceUpAnimation>
                <h2 className="text-center">About Frosty</h2>
                <p className="text-center text-justify">
                  FROSTY aims to be the first NFT live marketplace that brings
                  your arts and style into native digital realms. NFT + Defi
                  protocol and infrastructure with two functions: An independent
                  and open NFT ecosystem where collectors will meet artists and
                  buy them on the blockchain space.
                </p>
              </BounceUpAnimation>
            </div>
          </div>
        </div>

        <div className="container py-md-5 py-4">
          <RowSection>
            <div className="col-sm-6 mb-md-0 mb-3">
              <BounceLeftAnimation>
                <h2>What is $FRST?</h2>
                <Description>
                  FROSTY’s Native ERC-20 Token. $FRST is a utility token that is
                  intrinsically incorporated throughout the protocol’s
                  architecture, serving to further incentivize utility and
                  application in the Collector/Artist economy.
                </Description>
              </BounceLeftAnimation>
            </div>
            <div className="col-sm-6">
              <BounceRightAnimation>
                <img className="w-100" src="images/what-is-frosty.png" alt="" />
              </BounceRightAnimation>
            </div>
          </RowSection>
        </div>

        <div className="container py-md-5 py-4">
          <RowSection>
            <div className="col-sm-6 order-md-0 order-2">
              <BounceLeftAnimation>
                <img className="w-100" src="images/earn-frosty.png" alt="" />
              </BounceLeftAnimation>
            </div>
            <div className="col-sm-6 order-1 mb-md-0 mb-3">
              <BounceRightAnimation>
                <h2>How do I earn $FRST?</h2>
                <Description>
                  Staking your Frosty Genesis, Frosty NFT art and/or by
                  providing liquidity to the Frosty Platform pool.
                </Description>
              </BounceRightAnimation>
            </div>
          </RowSection>
        </div>

        <div className="container py-md-5 py-4">
          <RowSection>
            <div className="col-sm-6 mb-md-0 mb-3">
              <BounceLeftAnimation>
                <h2>What is the use of $FRST?</h2>
                <Description>
                  $FRST is the gas that ties together a triad of interdependence
                  between Collectors, Developers and Artists in a world that is
                  merging the digital and real. $FRST can be used to purchase
                  the digital arts items listed on the FROSTY marketplace.
                </Description>
              </BounceLeftAnimation>
            </div>
            <div className="col-sm-6">
              <BounceRightAnimation>
                <img className="w-100" src="images/use-of-frosty.png" alt="" />
              </BounceRightAnimation>
            </div>
          </RowSection>
        </div>

        <RoadmapTimeline />

        <div className="container py-md-5 py-4">
          <h2 className="text-center mb-4">The People Behind The Scenes</h2>
          <div className='row team'>
            <div className='col-sm-4 mb-md-0 mb-3'>
              <a href="https://t.me/Blockchainerr"
                target="_blank"
                rel="noopener noreferrer"
                className='holder'
              >
                <span className='photo'>
                  <img src='images/member1.png' alt='@Blockchainerr' />
                </span>
                <span className='details'>
                  <span className='name'>@Blockchainerr</span>
                  <small>MSc in Networks and Security</small>
                </span>
              </a>
            </div>
            <div className='col-sm-4 mb-md-0 mb-3'>
              <a href="https://t.me/Crypt087"
                target="_blank"
                rel="noopener noreferrer"
                className='holder'
              >
                <span className='photo'>
                  <img src='images/member2.png' alt='@Crypt087' />
                </span>
                <span className='details'>
                  <span className='name'>@Crypt087</span>
                  <small>MSc in Software Engineering</small>
                </span>
              </a>
            </div>
            <div className='col-sm-4 mb-md-0 mb-3'>
              <a href="https://t.me/MetaChaotic"
                target="_blank"
                rel="noopener noreferrer"
                className='holder'
              >
                <span className='photo'>
                  <img src='images/member3.png' alt='@MetaChaotic' />
                </span>
                <span className='details'>
                  <span className='name'>@MetaChaotic</span>
                  <small>MSc in Software Engineering</small>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

const Description = styled.p.attrs(() => ({
  className: "text-justify",
}))`
  //custom styling also can be added here along with bootsrap components, eg:
  //font-weight: bold,
`;

const RowSection = styled.div.attrs(() => ({
  className: "row align-items-center",
}))``;
