import ScrollAnimation from "react-animate-on-scroll";
import styled from "styled-components";

export const BounceRightAnimation = styled(ScrollAnimation).attrs(() => ({
  animateIn: "bounceInRight",
  animateOut: "bounceOutRight",
}))``;

export const BounceLeftAnimation = styled(ScrollAnimation).attrs(() => ({
  animateIn: "bounceInLeft",
  animateOut: "bounceOutLeft",
}))``;

export const BounceUpAnimation = styled(ScrollAnimation).attrs(() => ({
  animateIn: "bounceInUp",
  animateOut: "bounceOutUp",
}))``;

export const BounceDownAnimation = styled(ScrollAnimation).attrs(() => ({
  animateIn: "bounceInDown",
  animateOut: "bounceOutDown",
}))``;

export const FadeAnimation = styled(ScrollAnimation).attrs(() => ({
  animateIn: "fadeIn",
  animateOut: "fadeOut",
}))``;
