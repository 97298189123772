import React, { useEffect, useState, useCallback } from "react";
import { Steps, Card, Spinner } from "../components";
import fromExponential from "from-exponential";
import { Link } from "react-router-dom";
import FrostyGenesisNftAbi from "../contracts/FrostyGenesisNFT.json";
import FrostyGenesisStakingAbi from "../contracts/FrostyGenesisStaking.json";
import FrostyRewardsAbi from "../contracts/FrostyRewards.json";
import LpStakingAbi from "../contracts/LpStaking.json";
import LpRewardsAbi from "../contracts/LpRewards.json";
import FrostyGarmentNftAbi from "../contracts/FrostyGarmentNFT.json";
import FrostyNFTStakingAbi from "../contracts/FrostyNFTStaking.json";

const Dashboard = (props) => {
  const [initCompleted, setInitCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stakeLoading, setStakeLoading] = useState(false);
  const [unstakeLoading, setUnstakeLoading] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [zapLoading, setZapLoading] = useState(false);
  const [error, setError] = useState("");
  const [accounts, setAccounts] = useState(props.accounts);

  const [genesisNftContract, setGenenisNftContract] = useState();
  const [genesisStakingContract, setGenesisStakingContract] = useState();
  const [frostyRewardsContract, setFrostyRewardsContract] = useState();
  const [lpStakingContract, setLpStakingContract] = useState();
  const [lpRewardsContract, setLpRewardsContract] = useState();
  const [frostyGarmentNftContract, setFrostyGarmentNftContract] = useState();
  const [frostyStakingContract, setFrostyStakingContract] = useState();

  const [genesisDailyROI, setGenesisDailyROI] = useState(0);
  const [genesisStakingRewards, setGenesisStakingRewards] = useState(0);
  const [genenisTokenId, setGenenisTokenId] = useState();
  const [genenisIsStaked, setGenesisIsStaked] = useState(Boolean);
  const [genenisAvailable, setGenenisAvailable] = useState(Boolean);

  const [stakedLpBalance, setStakedLpBalance] = useState(0);
  const [zapAmount, setZapAmount] = useState(0);
  const [unzapAmount, setUnzapAmount] = useState(0);
  const [lpStakingRewards, setLpStakingRewards] = useState(0);
  const [lpDailyROI, setLpDailyROI] = useState(0);

  const [frostyNftCount, setFrostyNftCount] = useState(0);
  const [frostyNftDailyROI, setFrostyNftDailyROI] = useState(0);

  const zapMinimumContribution=0.4;

  /* Update all */
  const updateAll = useCallback(async () => {
    /* Get Genesis Staking Rewards */
    const getGenesisStakingRewards = async () => {
      const _unclaimedRewards = await genesisStakingContract.methods.unclaimedRewards(props.accounts[0]).call();
      let _amount = beautifyAmount(_unclaimedRewards);
      await setGenesisStakingRewards(_amount);
    }

    // Even if the user has many Genesis, we will only use the first one in the array
    const getGenesisTokenId = async () => {
      const _genesisBalance = await genesisNftContract.methods.balanceOf(props.accounts[0]).call();

      // If _genesisBalance equal 1 or bigger, then we have unstaked NFTs. We get the first one, with index 0
      if (_genesisBalance > 0) {
        const _id = await genesisNftContract.methods.tokenOfOwnerByIndex(props.accounts[0], 0).call();
        setGenenisTokenId(_id);
        setGenesisIsStaked(false);
        setGenenisAvailable(true);
      }

      // To check if we have staked NFTs, We check the genesisStakingContract
      const _stakedNftIds = await genesisStakingContract.methods.getStakedTokens(props.accounts[0]).call();

      if (_stakedNftIds.length > 0) {
        const _id = _stakedNftIds[0];
        setGenenisTokenId(_id);
        setGenesisIsStaked(true);
        setGenenisAvailable(true);
      }
    }

    /* Get Genesis Daily ROI */
    const getGenesisDailyReturn = async () => {
      const _dailyROI = await frostyRewardsContract.methods.getGenesisDailyAPY().call();
      await setGenesisDailyROI(Math.floor(_dailyROI / (10 ** 18)));
    }

    /* Get Staked LP Balance */
    const getStakedLpBalance = async () => {
      const _stakedLpBalance = await lpStakingContract.methods.getStakedBalance(props.accounts[0]).call();
      let _amount = beautifyAmount(_stakedLpBalance);
      await setStakedLpBalance(_amount);
    }

    /* Get LP Staking Rewards */
    const getLpStakingRewards = async () => {
      const _unclaimedRewards = await lpStakingContract.methods.unclaimedRewards(props.accounts[0]).call();
      let _amount = beautifyAmount(_unclaimedRewards);
      await setLpStakingRewards(_amount);
    }

    /* Get LP Daily ROI */
    const getLpDailyReturn = async () => {
      const _dailyROI = await lpRewardsContract.methods.getLpDailyAPY().call();
      await setLpDailyROI(Math.floor(_dailyROI / (10 ** 18)));
    }

    // Get the count of digital arts NFTs
    const getFrostyNftsCount = async () => {
      const _frostyBalance = await frostyGarmentNftContract.methods.balanceOf(props.accounts[0]).call();
      let _frostyNftIds = [];

      // If _frostyBalance equal 1 or bigger, then we have unstaked NFTs. We get their IDs
      if (_frostyBalance > 0) {
        for (let i = 0; i < _frostyBalance; i++) {
          const _id = await frostyGarmentNftContract.methods.tokenOfOwnerByIndex(props.accounts[0], i).call();
          _frostyNftIds.push(_id);
        }
      }

      // To check if we have staked NFTs, We check the frostyStakingContract
      // We put their IDs with the same array of the unstaked
      const _stakedNftsIds = await frostyStakingContract.methods.getStakedTokens(props.accounts[0]).call();
      if (_stakedNftsIds.length > 0) {
        _frostyNftIds = _frostyNftIds.concat(_stakedNftsIds);
      }

      const _frostyNftCount = _frostyNftIds.length;
      setFrostyNftCount(_frostyNftCount);
    }

    /* Get Frosty Daily ROI */
    const getFrostyNftDailyReturn = async () => {
      const _dailyROI = await frostyRewardsContract.methods.getParentDailyAPY().call();
      await setFrostyNftDailyROI(Math.floor(_dailyROI / (10 ** 18)));
    }

    if (genesisNftContract && genesisStakingContract && frostyRewardsContract && lpStakingContract && lpRewardsContract && frostyGarmentNftContract && frostyStakingContract && props.accounts && props.accounts.length > 0) {
      await getGenesisStakingRewards();
      await getGenesisTokenId();
      await getGenesisDailyReturn();
      await getStakedLpBalance();
      await getLpStakingRewards();
      await getLpDailyReturn();
      await getFrostyNftsCount();
      await getFrostyNftDailyReturn();
    }
  }, [genesisNftContract, genesisStakingContract, frostyRewardsContract, lpStakingContract, lpRewardsContract, frostyGarmentNftContract, frostyStakingContract, props.accounts]);


  /* Beautify Amount */
  const beautifyAmount = (amount) => {
    let decimalNum = amount / (10 ** 18);
    let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (4 || -1) + '})?');// eslint-disable-line
    return decimalNum.toString().match(re)[0];
  }

  /* init */
  const init = useCallback(async () => {
    if (initCompleted || loading || !props.web3 || !props.accounts) {
      return;
    }

    setLoading(true);

    // Init genesis NFT contract
    const _genesisNftContract = new props.web3.eth.Contract(
      FrostyGenesisNftAbi.abi,
      process.env.REACT_APP_GENESIS_NFT
    );

    // Init genesis nft staking contract
    const _genesisStakingContract = new props.web3.eth.Contract(
      FrostyGenesisStakingAbi.abi,
      process.env.REACT_APP_GENESIS_STAKING
    );

    // Init genesis nft rewards contract
    const _frostyRewardsContract = new props.web3.eth.Contract(
      FrostyRewardsAbi.abi,
      process.env.REACT_APP_FROSTY_REWARDS
    );

    // Init LP staking contract
    const _lpStakingContract = new props.web3.eth.Contract(
      LpStakingAbi.abi,
      process.env.REACT_APP_LP_STAKING
    );

    // Init LP rewards contract
    const _lpRewardsContract = new props.web3.eth.Contract(
      LpRewardsAbi.abi,
      process.env.REACT_APP_LP_REWARDS
    );

    // Init Frosty NFT contract
    const _frostyGarmentNftContract = new props.web3.eth.Contract(
      FrostyGarmentNftAbi.abi,
      process.env.REACT_APP_GARMENT_NFT
    );

    // Init Frosty nft staking contract
    const _frostyStakingContract = new props.web3.eth.Contract(
      FrostyNFTStakingAbi.abi,
      process.env.REACT_APP_NFT_STAKING
    );

    setGenenisNftContract(_genesisNftContract);
    setGenesisStakingContract(_genesisStakingContract);
    setFrostyRewardsContract(_frostyRewardsContract);
    setLpStakingContract(_lpStakingContract);
    setLpRewardsContract(_lpRewardsContract);
    setFrostyGarmentNftContract(_frostyGarmentNftContract);
    setFrostyStakingContract(_frostyStakingContract);
    setInitCompleted(true);
    setLoading(false);

    // Saving value in local storage to avoid disconnecting on refresh
    window.localStorage.setItem('account', 'connected');
  }, [initCompleted, loading, props.web3, props.accounts])

  init();

  /* useEffect */
  useEffect(() => {
    const interval = setInterval(() => updateAll(), 10000) // every 10 seconds
    return () => clearInterval(interval)
  }, [updateAll])

  useEffect(() => {
    setAccounts(props.accounts);

    // If account is saved in local storage, trigger click on the connect button
    if (window.localStorage.getItem('account') !== null) {
      props.onConnect();
    }

    updateAll();
  }, [props, props.accounts, updateAll]);

  /* Stake Genesis */
  async function stakeGenesis(id) {
    setStakeLoading(true);

    // If approved it should return the staking contract address, Else it should return 0x0000000000000
    const _alreadyApprovedAddress = await genesisNftContract.methods.getApproved(id).call();

    try {
      if (_alreadyApprovedAddress.toUpperCase() !== process.env.REACT_APP_GENESIS_STAKING.toUpperCase()) {
        await genesisNftContract.methods.approve(process.env.REACT_APP_GENESIS_STAKING, id).send({ from: props.accounts[0] });
      }

      await genesisStakingContract.methods.stake(id).send({ from: props.accounts[0] });
      await updateAll();
      alert("You have staked your Frosty Genesis NFT");
    } catch (err) {
      console.error(err);
    }

    setStakeLoading(false);
  }

  /* Unstake Genesis */
  async function unstakeGenesis(id) {
    setUnstakeLoading(true);

    try {
      await genesisStakingContract.methods.unstake(id).send({ from: props.accounts[0] });
      await updateAll();
      alert("You have unstaked your Frosty Genesis NFT");
    } catch (err) {
      console.error(err);
    }

    setUnstakeLoading(false);
  }

  /* Claim Genesis Rewards */
  async function claimGenesisRewards() {
    if (parseFloat(genesisStakingRewards) === 0) {
      alert("No earnings yet!");
      return;
    }

    setClaimLoading(true);

    try {
      await genesisStakingContract.methods.claimReward(props.accounts[0]).send({ from: props.accounts[0] });
      await updateAll();
    } catch (err) {
      console.error(err);
    }

    setClaimLoading(false);
  }

  /* Zap */
  async function zap() {
    if (zapAmount < 0.4) {
      alert(`The minimum amount is ${zapMinimumContribution} BNB`)
      return;
    }
    else {
      setZapLoading(true);

      let _amount = zapAmount * (10 ** 18);

      try {
        await lpStakingContract.methods
          .zapEth()
          .send({ from: props.accounts[0], value: _amount });
        await updateAll();
        alert("You have successfully staked your FRST LP");
      } catch (err) {
        console.error(err);
      }

      setZapLoading(false);
    }
  }

  /* Unzap */
  async function unzap() {
    setZapLoading(true);

    let _amount = fromExponential(unzapAmount * (10 ** 18));

    alert("You are about to unzap " + unzapAmount + " FRST LP. Make sure to use Unzap Max if you want to unstake everything.");

    try {
      await lpStakingContract.methods
        .unstake(_amount)
        .send({ from: props.accounts[0] });
      await updateAll();
    } catch (err) {
      console.error(err);
    }

    setZapLoading(false);
  }

  /* Unzap Max */
  async function unzapMax() {
    setZapLoading(true);
    const _stakedLpBalance = await lpStakingContract.methods.getStakedBalance(props.accounts[0]).call();
    alert("You are about to unzap all your FRST LP");

    try {
      await lpStakingContract.methods
        .unstake(_stakedLpBalance)
        .send({ from: props.accounts[0] });
      await updateAll();
    } catch (err) {
      console.error(err);
    }

    setZapLoading(false);
  }

  /* Claim Lp Rewards */
  async function claimLpRewards() {
    if (parseFloat(lpStakingRewards) === 0) {
      alert("No earnings yet!");
      return;
    }

    setClaimLoading(true);

    try {
      await lpStakingContract.methods.claimReward(props.accounts[0]).send({ from: props.accounts[0] });
      await updateAll();
    } catch (err) {
      console.error(err);
    }

    setClaimLoading(false);
  }

  /* Add FRST to metamask */
  async function addFrostToken(){
    const frstSymbol = 'frst';
    const frstDecimals = 18;

    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: process.env.REACT_APP_FROSTY_TOKEN,
            symbol: frstSymbol,
            decimals: frstDecimals, 
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="relative bg-white py-5">
        <div className="container">
          {(!accounts || accounts.length === 0) && (
            <div className="text-center">
              <button className="btn btn-primary mb-5" onClick={props.onConnect} >
                {loading && <Spinner color="white" size={24} />}
                {!loading && (error !== "" ? error : "Connect Metamask Wallet")}
              </button>

              <Steps />
            </div>
          )}

          {(accounts && accounts.length > 0) && (
            <div className="row justify-content-center text-center">
              <div className="col-md-6 mb-4">
                <Card title="Genesis">
                  <div className='mb-3'>
                    {genenisAvailable ? <div>You own a Genesis NFT (id: {genenisTokenId})</div> : <div>You don't have a Genesis NFT</div>}
                  </div>

                  {(genenisAvailable && !genenisIsStaked) && (
                    <button onClick={() => stakeGenesis(genenisTokenId)} className="btn btn-primary">
                      {stakeLoading ? (<Spinner size={24} color="white" />) : (
                        <>
                          <span>STAKE</span>
                        </>
                      )}
                    </button>
                  )}
                  {(genenisAvailable && genenisIsStaked) && (
                    <button onClick={() => unstakeGenesis(genenisTokenId)} className="btn btn-primary">
                      {unstakeLoading ? (<Spinner size={24} color="white" />) : (
                        <span>UNSTAKE</span>
                      )}
                    </button>
                  )}

                  <div className='mt-4 pt-4 border-top'>
                    <div className='mb-3'>
                      Current APY: <strong className="text-secondary">{genesisDailyROI} %</strong><br />
                      Rewards: <strong className="text-secondary">{genesisStakingRewards} FRST</strong><br />
                      <button className='btn btn-link text-primary btn-sm' onClick={() => addFrostToken()}><small>(Add FRST to metamask)</small></button>
                    </div>

                    {(genesisStakingRewards > 0) && (
                      <button type="submit" className="btn btn-primary" onClick={() => claimGenesisRewards()}>
                        {claimLoading ? (<Spinner size={24} color="white"/>) : (
                          <span>CLAIM</span>
                        )}
                      </button>
                    )}
                  </div>
                </Card>
              </div>

              <div className="col-md-6 mb-4">
                <Card title="Digital Arts">
                  <div className='mb-3'>
                    {frostyNftCount > 0 ? <div>You own a digital art NFT</div> : <div>You don't have any digital arts NFT</div>}                    
                  </div>

                  {(frostyNftCount > 0) && (
                    <Link className='btn btn-primary' to="/nft-staking">MY NFT</Link>
                  )}

                  <div className='mt-4 pt-4 border-top'>
                    Current APY: <strong className="text-secondary">{frostyNftDailyROI} %</strong>
                  </div>
                </Card>
              </div>

              <div className="col-md-4">
                <Card title="Zap bnb">
                  <div className='mb-4'>
                    Staked LP:&nbsp;
                    <strong className="text-secondary">{stakedLpBalance} FRST LP</strong>
                  </div>

                  <div className='row no-gutters mb-3'>
                    <div className='col-md-6'>
                      <input
                        type="number"
                        maxLength="6"
                        min={zapMinimumContribution}
                        step="0.01"
                        value={zapAmount}
                        onChange={(e) => setZapAmount(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className='col-md-6'>
                      <button onClick={() => zap()} className="btn btn-primary btn-block">
                        {zapLoading ? (<Spinner size={24} color="white" />) : (
                          <>
                            <span>ZAP BNB</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>

                  {(stakedLpBalance > 0) && (
                    <div className='row no-gutters'>
                      <div className='col-md-6'>
                        <input
                          type="number"
                          maxLength="18"
                          min="0.0001"
                          max={stakedLpBalance}
                          step="0.0001"
                          value={unzapAmount}
                          onChange={(e) => setUnzapAmount(e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className='col-md-6'>
                        <button onClick={() => unzap()} className="btn btn-primary btn-block">
                          {zapLoading ? (<Spinner size={24} color="white" />) : (
                            <>
                              <span>UNZAP BNB</span>
                            </>
                          )}
                        </button>
                      </div>
                      <div className='col-12'>
                        <button className='btn btn-link btn-sm' onClick={() => unzapMax()}>
                          <small>Unzap Max</small>
                        </button>
                      </div>
                    </div>
                  )}

                  <div className='mt-4 pt-4 border-top'>
                    <div className='mb-3'>
                      Current APY: <strong className="text-secondary">{lpDailyROI} %</strong><br />
                      Rewards: <strong className="text-secondary">{lpStakingRewards} FRST</strong>
                    </div>

                    {(lpStakingRewards > 0) && (
                      <button type="submit" className="btn btn-primary" onClick={() => claimLpRewards()}>
                        {claimLoading ? (<Spinner size={24} color="white" />) : (
                          <span>CLAIM</span>
                        )}
                      </button>
                    )}
                  </div>
                </Card>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;