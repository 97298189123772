import React, { useEffect, useState, useCallback } from "react";
import { Steps, Card, Spinner } from "../components";
import { Link } from "react-router-dom";
import FrostyGarmentNftAbi from "../contracts/FrostyGarmentNFT.json";
import FrostyNFTStakingAbi from "../contracts/FrostyNFTStaking.json";

const NftStaking = (props) => {
  const [initCompleted, setInitCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stakeLoading, setStakeLoading] = useState(false);
  const [unstakeLoading, setUnstakeLoading] = useState(false);
  const [error, setError] = useState("");
  const [accounts, setAccounts] = useState(props.accounts);

  const [frostyGarmentNftContract, setFrostyGarmentNftContract] = useState();
  const [frostyStakingContract, setFrostyStakingContract] = useState();

  const [frostyNftCount, setFrostyNftCount] = useState(0);
  const [nftsList, setNftsList] = useState([]);
  const [nftStakingRewards, setNftStakingRewards] = useState(0);

  const [mainFstaId, setMainFstaId] = useState();

  /* Update all */
  const updateAll = useCallback(async () => {
    /* Get User NFTs */
    const getFrostyNfts = async () => {
      let _allNftsIds = [];
      const _frostyBalance = await frostyGarmentNftContract.methods.balanceOf(props.accounts[0]).call();

      // If _frostyBalance bigger than 0, then we have unstaked NFTs. We get their IDs and store it
      if (_frostyBalance > 0) {
        for (let i = 0; i < _frostyBalance; i++) {
          const _id = await frostyGarmentNftContract.methods.tokenOfOwnerByIndex(props.accounts[0], i).call();
          _allNftsIds.push(_id);
        }
      }

      // We check if we have staked NFTs inside the frostyStakingContract
      // We put their IDs with the same array with the unstaked IDs
      const _stakedNftsIds = await frostyStakingContract.methods.getStakedTokens(props.accounts[0]).call();
      if (_stakedNftsIds.length > 0) {
        _allNftsIds = _allNftsIds.concat(_stakedNftsIds);
      }

      // We get the count of NFTs
      const _frostyNftCount = _allNftsIds.length;
      setFrostyNftCount(_frostyNftCount);

      // TEMP: Till fixing the ability to stake more than 1 FSTA
      _allNftsIds.sort();
      const _mainFstaId = _allNftsIds[0]
      setMainFstaId(_mainFstaId);

      // We get the meta for each NFT and we store it. We add to each NFT the id and if it is staked or no
      let _nftDetailsList = [];
      if (_frostyNftCount > 0) {
        // for (let i = 0; i < _frostyNftCount; i++) {
        //   const _id = _allNftsIds[i];
        //   const _nftUri = await frostyGarmentNftContract.methods.tokenURI(_id).call();
        //   await fetch(_nftUri)
        //     .then(res => res.json())
        //     .then(data => {
        //       data['id'] = _id;
        //       data['staked'] = _stakedNftsIds.includes(_allNftsIds[i]);
        //       _nftDetailsList.push(data);
        //     })
        // }

        // TEMP: Till fixing the ability to stake more than 1 FSTA
        const _nftUri = await frostyGarmentNftContract.methods.tokenURI(_mainFstaId).call();
        await fetch(_nftUri)
          .then(res => res.json())
          .then(data => {
            data['id'] = _mainFstaId;
            data['staked'] = _stakedNftsIds.includes(_mainFstaId);
            _nftDetailsList.push(data);
          })

        setNftsList(_nftDetailsList);
      }
    }

    /* Get NFT Staking Rewards */
    const getNftStakingRewards = async () => {
      const _unclaimedRewards = await frostyStakingContract.methods.unclaimedRewards(props.accounts[0]).call();
      let _amount = beautifyAmount(_unclaimedRewards);
      await setNftStakingRewards(_amount);
    }

    if (props.accounts && props.accounts.length > 0 && frostyGarmentNftContract && frostyStakingContract) {
      await getFrostyNfts();
      await getNftStakingRewards();
    }
  }, [props.accounts, frostyGarmentNftContract, frostyStakingContract]);


  /* init */
  const init = useCallback(async () => {
    if (initCompleted || loading || !props.web3 || !props.accounts) {
      return;
    }

    setLoading(true);

    // Init Frosty NFT contract
    const _frostyGarmentNftContract = new props.web3.eth.Contract(
      FrostyGarmentNftAbi.abi,
      process.env.REACT_APP_GARMENT_NFT
    );

    // Init Frosty nft staking contract
    const _frostyStakingContract = new props.web3.eth.Contract(
      FrostyNFTStakingAbi.abi,
      process.env.REACT_APP_NFT_STAKING
    );

    setFrostyGarmentNftContract(_frostyGarmentNftContract);
    setFrostyStakingContract(_frostyStakingContract);
    setInitCompleted(true);
    setLoading(false);

    // Saving value in local storage to avoid disconnecting on refresh
    window.localStorage.setItem('account', 'connected');
  }, [initCompleted, loading, props.web3, props.accounts])

  init();


  /* useEffect */
  useEffect(() => {
    setAccounts(props.accounts);

    // If account is saved in local storage, trigger click on the connect button
    if (window.localStorage.getItem('account') !== null) {
      props.onConnect();
    }

    updateAll();
  }, [props, props.accounts, updateAll]);


  /* Stake NFT */
  async function stakeFrostyNft(id) {
    setStakeLoading(true);

    // If approved it should return the staking contract address, Else it should return 0x0000000000000
    const _alreadyApprovedAddress = await frostyGarmentNftContract.methods.getApproved(id).call();

    try {
      if (_alreadyApprovedAddress.toUpperCase() !== process.env.REACT_APP_NFT_STAKING.toUpperCase()) {
        await frostyGarmentNftContract.methods.approve(process.env.REACT_APP_NFT_STAKING, id).send({ from: props.accounts[0] });
      }

      await frostyStakingContract.methods.stake(id).send({ from: props.accounts[0] });
      await updateAll();
      alert("You have staked your Frosty NFT");
    } catch (err) {
      console.error(err);
    }

    setStakeLoading(false);
  }

  /* Unstake Frosty NFT */
  async function unstakeFrostyNft(id) {
    setUnstakeLoading(true);

    try {
      await frostyStakingContract.methods.unstake(id).send({ from: props.accounts[0] });
      await updateAll();
      alert("You have unstaked your Frosty NFT");
    } catch (err) {
      console.error(err);
    }

    setUnstakeLoading(false);
  }

  /* Beautify Amount */
  const beautifyAmount = (amount) => {
    let decimalNum = amount / (10 ** 18);
    let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (4 || -1) + '})?');// eslint-disable-line
    return decimalNum.toString().match(re)[0];
  }

  /* Claim Genesis Rewards */
  async function claimNftRewards() {
    if (parseFloat(nftStakingRewards) === 0) {
      alert("No earnings yet!");
      return;
    }

    setLoading(true);

    try {
      await frostyStakingContract.methods.claimReward(props.accounts[0]).send({ from: props.accounts[0] });
      await updateAll();
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  }

  /* NFTs List */
  const NftsList = () => {
    return (
      <table className="table table-striped table-bordered table-hover table-light">
        <thead className="thead-light">
          <tr>
            <th>NFT</th>
            <th>Id</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {nftsList.map((nft, index) => {
            return (
              <tr key={index}>
                <td>
                  <Link className='holder mb-1' to={`/auctions/${nft.id}`}><img src={nft.image} alt="Frosty NFT" className="w-100" /></Link>
                  <div className='nft-name'>{nft.name}</div>
                </td>
                <td width="80">{nft.id}</td>
                <td width="160">
                  {(!nft.staked) && (
                    <button onClick={() => stakeFrostyNft(nft.id)} className="btn btn-primary btn-block">
                      {stakeLoading ? (<Spinner size={24} color="white" />) : (
                        <>
                          <span>STAKE</span>
                        </>
                      )}
                    </button>
                  )}
                  {(nft.staked) && (
                    <button onClick={() => unstakeFrostyNft(nft.id)} className="btn btn-primary btn-block">
                      {unstakeLoading ? (<Spinner size={24} color="white" />) : (
                        <span>UNSTAKE</span>
                      )}
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    )
  }

  return (
    <>
      <div className="relative bg-white py-5">
        <div className="container">
          {(!accounts || accounts.length === 0) && (
            <div className="text-center">
              <button className="btn btn-primary mb-5" onClick={props.onConnect} >
                {loading && <Spinner color="white" size={24} />}
                {!loading && (error !== "" ? error : "Connect Metamask Wallet")}
              </button>

              <Steps />
            </div>
          )}

          {(accounts && accounts.length > 0) && (
            <div className="row justify-content-md-center">
              <div className="col-md-6 text-center">
                <Card title="Digital Arts Staking">
                  {nftsList && nftsList.length > 0 ? <NftsList /> : <h5>No Digital Arts NFTs Found</h5>}

                  <div className='mt-3'>
                    Claimable rewards: {nftStakingRewards} FRST
                  </div>

                  {(nftStakingRewards > 0) && (
                    <div className='mt-3'>
                      <button type="submit" className="btn btn-primary" onClick={() => claimNftRewards()}>
                        {loading ? (<Spinner size={24} color="white" />) : (
                          <span>CLAIM</span>
                        )}
                      </button>
                    </div>
                  )}
                </Card>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NftStaking;