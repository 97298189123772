import React, { useRef } from "react";
import { GLTFModel } from "react-3d-viewer";

const ThreeDModelViewer = (props) => {
  const ref = useRef(null);
  // Default width and height to stay within the limit
  let modelWidth, modelHeight = '380';

  // We get the responsive width on load, and we set the height equal to it
  if (ref.current != null) {
    modelWidth = ref.current.offsetWidth;
    modelHeight = modelWidth;
  }

  return (
    <div ref={ref}>
      <GLTFModel 
        src={props.glbFile} 
        width={modelWidth} 
        height={modelHeight} 
      ></GLTFModel>
    </div>
  );
};

export default ThreeDModelViewer;
