import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { initWeb3 } from "./utils.js";
import { Footer, Header } from "./components";
import LandingPage from "./pages/LandingPage";
import GenesisSale from "./pages/GenesisSale";
import Dashboard from "./pages/Dashboard";
import NftStaking from "./pages/NftStaking";
import Auctions from "./pages/Auctions";
import AuctionDetails from "./pages/AuctionDetails";

const App = () => {
  const [web3, setWeb3] = useState();
  const [accounts, setAccounts] = useState();
  const [initCompleted, setInitCompleted] = useState(false);

  const init = async () => {
    const mainnetNetworkId = parseInt(process.env.REACT_APP_NETWORK_ID);
    if (initCompleted) {
      return;
    }

    let _web3;
    try {
      _web3 = await initWeb3();
    } catch (err) {
      console.error(err);
      return;
    }

    const _accounts = await _web3.eth.getAccounts();
    const networkId = await _web3.eth.net.getId();

    if (networkId !== mainnetNetworkId) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x' + mainnetNetworkId.toString(16) }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: '0x' + mainnetNetworkId.toString(16),
                chainName: 'Binance Smart Chain',
                nativeCurrency:
                {
                  name: 'BNB',
                  symbol: 'BNB',
                  decimals: 18
                },
                rpcUrls: ['https://bsc-dataseed.binance.org/'],
                blockExplorerUrls: ['https://bscscan.com/'],
              }],
            });
          } catch (addError) {
            console.log('Add Error: ' + addError)
          }
        }
        console.log('Switch Error: ' + switchError.code);
        alert("Please select the Binance Chain Network from your metamask");
      }

      return;
    }

    window.ethereum.on("accountsChanged", (accounts) => {
      setAccounts(accounts);
      // if accounts list is empty or not provided then set initCompleted to false
      setInitCompleted(accounts && accounts.length > 0);
    });

    window.ethereum.on('chainChanged', (_chainId) => window.location.reload());

    setWeb3(_web3);
    setAccounts(_accounts);
    setInitCompleted(_accounts && _accounts.length > 0);
  };

  const disconnect = async () => {
    window.localStorage.removeItem("account");
    window.location.reload(false);
  };

  return (
    <>
      <Header onDisconnect={disconnect} accounts={accounts} />

      <Switch>
        <Route path="/" exact>
          <LandingPage />
        </Route>
        <Route path="/genesis" exact>
          <GenesisSale
            onConnect={init}
            onDisconnect={disconnect}
            web3={web3}
            accounts={accounts}
          />
        </Route>
        <Route path="/frostboard" exact>
          <Dashboard 
            onConnect={init}
            onDisconnect={disconnect}
            web3={web3} 
            accounts={accounts} />
        </Route>
        <Route path="/auctions" exact>
          <Auctions 
            onConnect={init}
            onDisconnect={disconnect}
            web3={web3} 
            accounts={accounts}
            archived='false' />
        </Route>
        <Route path="/auctions/archived" exact>
          <Auctions 
            onConnect={init}
            onDisconnect={disconnect}
            web3={web3} 
            accounts={accounts}
            archived='true' />
        </Route>
        <Route path="/auctions/:id" exact>
          <AuctionDetails 
            onConnect={init}
            onDisconnect={disconnect}
            web3={web3} 
            accounts={accounts} />
        </Route>
        <Route path="/nft-staking" exact>
          <NftStaking 
            onConnect={init}
            onDisconnect={disconnect}
            web3={web3} 
            accounts={accounts} />
        </Route>
      </Switch>

      <Footer />
    </>
  );
};

export default App;
