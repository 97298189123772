import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="container-fluid text-center py-3">
      <Link to="/" className="logo mb-2">
        <img src="/images/vertical-logo.svg" alt="FRSTY" />
      </Link>

      <div className="social my-1">
        <a
          href="https://instagram.com/the.frostyart"
          target="_blank"
          rel="noopener noreferrer"
          title='Follow us on Instagram'
        >
          <img src="/images/sm-instagram.svg" alt="Instagram" />
        </a>
        <a
          href="https://twitter.com/Frosty_DeFi"
          target="_blank"
          rel="noopener noreferrer"
          title='Follow us on Twitter'
        >
          <img src="/images/sm-twitter.svg" alt="Twitter" />
        </a>
        <a
          href="https://mrcryptofrosty1.gitbook.io/frosty/"
          target="_blank"
          rel="noopener noreferrer"
          title='Check our Gitbook'
        >
          <img src="/images/sm-gitbook.svg" alt="Gitbook" />
        </a>
        <a
          href="https://medium.com/@Frosty_"
          target="_blank"
          rel="noopener noreferrer"
          title='Check us on Medium'
        >
          <img src="/images/sm-medium.svg" alt="" />
        </a>
        <a href="https://t.me/Frosty_Announcements"
          title='Frosty Annoucements Channel'
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/sm-telegram.svg" alt="Telegram" />
        </a>
        <a
          href="https://t.me/official_frosty"
          title='Frosty Official Chat'
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/sm-telegram.svg" alt="Telegram" />
        </a>
      </div>

      <div className='menu my-2'>
        <a
          href="https://github.com/mrcryptofrosty/FROSTY-Whitepaper/blob/main/Frosty-Whitepaper.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Whitepaper
        </a>

        <a
          href="https://forum.frosty.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Forum
        </a>

        <a
          href="https://gov.frosty.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Proposals
        </a>

      </div>

      <div className="copyright">
        &copy; {new Date().getFullYear()} Copyright: Frosty
      </div>
    </footer>
  );
}
