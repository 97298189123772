import React from "react";
import Particles from "react-particles-js";

const ParticlesAnimation = () => {
  return (
    <Particles
      params={{
        particles: {
          number: {
            value: 100,
            density: {
              enable: true,
            },
          },
          size: {
            value: 3,
          },
        },
      }}
    />
  );
};

export default ParticlesAnimation;
