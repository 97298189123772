import React from "react";
import { Link, NavLink } from "react-router-dom";

/* Beautify address */
const beautifyAddress = (address) => {
  let _address = address.substring(0, 4) + "...." + address.substring(address.length - 4, address.length);
  return _address;
}

export default function Header(props) {
  let newAddress = '';
  if(props.accounts && props.accounts.length > 0){
    newAddress = beautifyAddress(props.accounts[0]);
  }

  return (
    <header className="container-fluid clearfix">
      <Link to="/" className="logo">
        <img src="/images/horizontal-logo.svg" alt="FROSTY" />
      </Link>

      <div className="main-menu">

        {props.accounts && props.accounts.length > 0 && (
          <button className="btn-sm" onClick={props.onDisconnect}>
            Disconnect {newAddress}
          </button>
        )}

        <NavLink to="/genesis">Genesis</NavLink>
        <NavLink to="/frostboard">Frostboard</NavLink> 
        <NavLink to="/auctions" archived='false'>Auctions</NavLink> 

        <div className='sub'>
          <span className='menu-parent'>Governance</span>
          <div className='menu'>
            <a
              href="https://forum.frosty.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Forum
            </a>
            <a
              href="https://gov.frosty.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Proposals
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
