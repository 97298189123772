import React, { useEffect, useState, useCallback } from "react";
import { Steps, Spinner } from "../components";
import { Link } from "react-router-dom";
import FrostyAuctionAbi from "../contracts/FrostyAuction.json";
import FrostyGarmentNftAbi from "../contracts/FrostyGarmentNFT.json";

const Auctions = (props) => {
  const [loading, setLoading] = useState(false);
  const [initCompleted, setInitCompleted] = useState(false);
  const [error, setError] = useState("");
  const [accounts, setAccounts] = useState(props.accounts);
  const [currentAuctionsList, setCurrentAuctionsList] = useState([]);
  const [closedAuctionsList, setClosedAuctionsList] = useState([]);
  const [isArchived, setIsArchived] = useState(props.archived);

  /* init */
  const init = useCallback(async () => {
    if (initCompleted || loading || !props.web3 || !props.accounts) {
      return;
    }

    setLoading(true);

    /* Init Auction Contract */
    const _frostyAuctionContract = new props.web3.eth.Contract(
      FrostyAuctionAbi.abi,
      process.env.REACT_APP_AUCTION
    )

    /* Init NFT Contract */
    const _frostyGarmentNftContract = new props.web3.eth.Contract(
      FrostyGarmentNftAbi.abi,
      process.env.REACT_APP_GARMENT_NFT
    );

    if (isArchived === 'false') {
      /* Get predifined current Auctions IDs from env */
      let _currentAuctionsIds = [];
      let _currentAuctionsList = [];

      if(process.env.REACT_APP_CURRENT_AUCTIONS.length > 0){
        _currentAuctionsIds = process.env.REACT_APP_CURRENT_AUCTIONS.split(',');
     
        /* Load current auctions info by ids */
        for (let i = 0; i < _currentAuctionsIds.length; i++) {
          const _id = _currentAuctionsIds[i];
          const _nftUri = await _frostyGarmentNftContract.methods.tokenURI(_id).call();
          const _highestBidder = await _frostyAuctionContract.methods.getHighestBidder(_id).call();

          await fetch(_nftUri)
            .then(res => res.json())
            .then(data => {
              data['id'] = _id;
              data['highestBid'] = beautifyAmount(_highestBidder._bid);
              _currentAuctionsList.push(data);
            })
        }
      }

      setCurrentAuctionsList(_currentAuctionsList);
    }

    if (isArchived === 'true') {
      /* Get predifined closed Auctions IDs from env */
      let _closedAuctionsIds = [];
      let _closedAuctionsList = [];

      if(process.env.REACT_APP_CLOSED_AUCTIONS.length > 0){
        _closedAuctionsIds = process.env.REACT_APP_CLOSED_AUCTIONS.split(',');
      
        /* Load closed auctions info by ids */
        for (let i = 0; i < _closedAuctionsIds.length; i++) {
          const _id = _closedAuctionsIds[i];
          const _nftUri = await _frostyGarmentNftContract.methods.tokenURI(_id).call();
          const _salePrice = await _frostyGarmentNftContract.methods.primarySalePrice(_id).call();

          await fetch(_nftUri)
            .then(res => res.json())
            .then(data => {
              data['id'] = _id;
              data['salePrice'] = beautifyAmount(_salePrice);
              _closedAuctionsList.push(data);
            })
        }
      }

      setClosedAuctionsList(_closedAuctionsList);
    }

    setInitCompleted(true);
    setLoading(false);

    // Saving value in local storage to avoid disconnecting on refresh
    window.localStorage.setItem('account', 'connected');
  }, [initCompleted, loading, props.web3, props.accounts, isArchived]);

  init();

  /* useEffect */
  useEffect(() => {
    const { accounts, archived } = props;
    setAccounts(accounts);
    setIsArchived(archived);

    // If account is saved in local storage, trigger click on the connect button
    if (window.localStorage.getItem('account') !== null) {
      props.onConnect();
    }
  }, [props, accounts]);

  /* Beautify Amount */
  const beautifyAmount = (amount) => {
    let _decimalNum = amount / (10 ** 18);
    let _re = new RegExp('^-?\\d+(?:\.\\d{0,' + (4 || -1) + '})?');// eslint-disable-line
    return _decimalNum.toString().match(_re)[0];
  }

  /* Toggle archived props */
  const toggleArchived = () => {
    if (isArchived === 'true') {
      setIsArchived('false');
    }
    else {
      setIsArchived('true');
    }
    setInitCompleted(false);
    init();
    window.scrollTo({ top: 0 });
  }

  /* Auctions Component */
  const Auctions = (props) => {
    return (
      <div className='row justify-content-md-center auctions-list mb-4'>
        {props.category.map((nft, index) => {
          return (
            <div className='col-lg-4 col-sm-6 mb-4' key={index}>
              <Link className='holder' to={`/auctions/${nft.id}`}>
                {(props.sold === 'true') && (
                  <span className='ribbon'>
                    <span className='bg-secondary'>Sold for<br />{nft.salePrice} BNB</span>
                  </span>
                )}
                {(props.sold === 'false') && (
                  <span className='ribbon'>
                    <span className='bg-navy'>Highest bid<br />{nft.highestBid} BNB</span>
                  </span>
                )}
                <span className='photo'>
                  <img src={nft.image} alt={nft.name} />
                </span>
                <span className='details'>
                  <span className='name'>{nft.name}</span>
                </span>
              </Link>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="relative bg-white py-5">
      <div className="container">
        {(!accounts || accounts.length === 0) && (
          <div className="text-center">
            <button className="btn btn-primary mb-5" onClick={props.onConnect}>
              {loading && <Spinner color="white" size={24} />}
              {!loading && (error !== "" ? error : "Connect Metamask Wallet")}
            </button>

            <Steps />
          </div>
        )}

        {accounts && accounts.length > 0 && (
          <div className='text-center'>
            {(isArchived === 'false' && currentAuctionsList) && (
              <>
                <h2 className='text-center mb-4'>Current Auctions</h2>

                {(currentAuctionsList.length > 0) && (
                  <Auctions category={currentAuctionsList} sold='false' />
                )}

                {(!loading && currentAuctionsList.length === 0) && (
                  <div className='mb-3'>
                    Currently, there aren't any running auctions! <br />
                    Make sure to follow us on our social medias to be notified about the next auction date.
                  </div>
                )}

                <Link to='/auctions/archived' onClick={toggleArchived} className='btn btn-primary'>View Archived Auctions</Link>
              </>
            )}

            {(isArchived === 'true' && closedAuctionsList && closedAuctionsList.length > 0) && (
              <>
                <h2 className='text-center mb-4'>Closed Auctions</h2>
                <Auctions category={closedAuctionsList} sold='true' />
                <Link to='/auctions' onClick={toggleArchived} className='btn btn-primary'>View Running Auctions</Link>
              </>
            )}
          </div>
        )}

        {loading && (
          <div className='page-loader'>
            <Spinner size={48} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Auctions;