import React, { useRef } from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";

const Modal = ({ children, onClose, title }) => {
  const ref = useRef(null);

  const closeModal = () => {
    document.querySelector("body").classList.remove("overflow-hidden");
    onClose();
  };

  useOnClickOutside(ref, () => {
    closeModal();
  });

  return (
    <div className="modal-bg">
      <div ref={ref} className="modal-content text-center">
        <div>{title}</div>
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
