import React, { useEffect, useState, useCallback } from "react";
import FrostyGenesisNftAbi from "../contracts/FrostyGenesisNFT.json";
import fromExponential from "from-exponential";
import Pagination from "react-js-pagination";
import { Steps, Card, Spinner } from "../components";

const GenesisSalePage = (props) => {
  const [loading, setLoading] = useState(false);
  const [contributeLoading, setContributeLoading] = useState(false);

  const [initCompleted, setInitCompleted] = useState(false);
  const [error, setError] = useState("");
  const [accounts, setAccounts] = useState(props.accounts);

  const [genesisNftContract, setGenenisNftContract] = useState();
  const [genesisTokenName, setGenesisTokenName] = useState();
  const [genesisTokenDescription, setGenesisTokenDescription] = useState();
  const [genesisTokenImage, setGenesisTokenImage] = useState('./images/genesis.png');

  const [remainingGenesisTokens, setRemainingGenesisTokens] = useState();
  const [amount, setAmount] = useState(0);
  const [allTransactions, setAllTransactions] = useState();
  const [minContribution, setMinContribution] = useState();
  const [maxContribution, setMaxContribution] = useState();
  const [contributedValue, setContributedValue] = useState(0);
  const [genenisTokenId, setGenenisTokenId] = useState(-1);
  const [genesisEndDate, setGenesisEndDate] = useState();
  const [genesisEndDateUtc, setGenesisEndDateUtc] = useState();

  /** Update all */
  const updateAll = useCallback(async () => {
    /* Get Genesis NFT meta Info */
    async function getTokenInfo() {
      if (genesisNftContract) {
        const requestUrl = await genesisNftContract.methods.tokenURI_().call();

        await fetch(requestUrl)
          .then((res) => res.json())
          .then((data) => {
            setGenesisTokenName(data.name);
            setGenesisTokenDescription(data.description);
            setGenesisTokenImage(data.animation_url);
          });
      }
    }

    /* Get Remaining Genesis NFTs */
    const getRemainingGenesisTokens = async () => {
      const _remainingGenesisTokens = await genesisNftContract.methods
        .remainingGenesisTokens()
        .call();
      setRemainingGenesisTokens(_remainingGenesisTokens);
    };

    /* Get all contributed values under 1 address */
    const getContributedValue = async () => {
      const _contributedValue = await genesisNftContract.methods
        .contribution(props.accounts[0])
        .call();
      setContributedValue(_contributedValue / 10 ** 18);
    };

    /* Get Genesis Token Id */
    const getGenesisTokenId = async () => {     
      const _genesisBalance = await genesisNftContract.methods.balanceOf(props.accounts[0]).call();
            
      if (_genesisBalance > 0) {
        const _id = await genesisNftContract.methods.tokenOfOwnerByIndex(props.accounts[0], 0).call();
        setGenenisTokenId(_id);
      }
      else{
        setGenenisTokenId(-1);
      }
    }

    /* Get all transactions */
    const getAllTransactions = async () => {
      const requestUrl = process.env.REACT_APP_API_BASE_URL + "/api?module=account&action=txlist&address=" + process.env.REACT_APP_GENESIS_NFT + "&startblock=0&endblock=99999999&sort=desc&apikey=" + process.env.REACT_APP_API_TOKEN;

      const response = await fetch(requestUrl)
        .then((res) => res.json())
        .then((data) => {
          const transactions =
            data && data.result && Array.isArray(data.result)
              ? data.result
              : [];

          return transactions.filter((item) => item.value > 0);
        });

      setAllTransactions(response);
    };

    if (genesisNftContract && accounts && accounts.length > 0) {
      await getRemainingGenesisTokens();
      await getAllTransactions();
      await getContributedValue();
      await getTokenInfo();
      await getGenesisTokenId();
    } else {
      // console.error('frosty token is not defined')
    }
  }, [genesisNftContract, accounts, props.accounts]);


  /* init */
  const init = useCallback(async () => {
    if (initCompleted || loading || !props.web3 || !props.accounts) {
      return;
    }

    setLoading(true);

    const _genesisNftContract = new props.web3.eth.Contract(
      FrostyGenesisNftAbi.abi,
      process.env.REACT_APP_GENESIS_NFT
    );

    const _minimumContributionAmount = await _genesisNftContract.methods
      .minimumContributionAmount()
      .call();

    const _maximumContributionAmount = await _genesisNftContract.methods
      .maximumContributionAmount()
      .call();

    const _endDate = await _genesisNftContract.methods.genesisEndTimestamp().call();

    setGenenisNftContract(_genesisNftContract);
    setMinContribution(_minimumContributionAmount / 10 ** 18);
    setMaxContribution(_maximumContributionAmount / 10 ** 18);

    let date = new Date(_endDate * 1000);
    let dateAsString = date.toUTCString();

    setGenesisEndDateUtc(dateAsString);
    setGenesisEndDate(_endDate);
    setInitCompleted(true);
    setLoading(false);

    // Saving value in local storage to avoid disconnecting on refresh
    window.localStorage.setItem('account', 'connected');

  }, [initCompleted, loading, props.web3, props.accounts]);

  init();

  /* useEffect */
  useEffect(() => {
    setAccounts(props.accounts);

    // If account is saved in local storage, trigger click on the connect button
    if (window.localStorage.getItem('account') !== null) {
      props.onConnect();
    }

    updateAll();
  }, [props.accounts, updateAll]);

  
  /* Contribute in Genesis sale */
  async function contribute() {
    setContributeLoading(true);
    const allowedContribution = maxContribution - contributedValue;

    if (allowedContribution < 0) {
      alert("ERROR: Negative value. Just for testing. Shouldn't reach it");
      setContributeLoading(false);
      return;
    }

    if (amount < minContribution || amount > maxContribution) {
      alert(
        `Contribution amount should be between ${minContribution} and ${maxContribution} BNB`
      );
      setContributeLoading(false);
      return;
    }

    if (amount > allowedContribution) {
      alert(
        `You are still allowed to contribute with only ${allowedContribution} BNB`
      );
      setContributeLoading(false);
      return;
    }

    const actual = amount * 10 ** 18;
    const value = fromExponential(actual);

    if (contributedValue > 0) {
      if (
        window.confirm(
          `Are you sure you want to increase your contribution with ${amount} BNB`
        )
      ) {
        try {
          const sender = accounts[0];
          await genesisNftContract.methods
            .increaseContribution()
            .send({ from: sender, value: value })
            .once("confirmation", () => {
              // console.log("CONFIRMED");
            });

          alert("Congratulation! You increased you Frosty Genesis contribution");
          await updateAll();
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      if (
        window.confirm(`Are you sure you want to contribute with ${amount} BNB`)
      ) {
        try {
          const sender = accounts[0];
          await genesisNftContract.methods.buy().send({ from: sender, value: value });
          alert("Congratulation! You own a Frosty Genesis");
          await updateAll();
        } catch (err) {
          console.log(err);
        }
      }
    }

    setContributeLoading(false);
  }

  /* List of transactions */
  const TransactionsList = () => {
    // Pagination Logic
    const transactionsPerPage = 10;
    const [activePage, setCurrentPage] = useState(1);
    const indexOfLastTransaction = activePage * transactionsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
    const currentTransactions = allTransactions.slice(
      indexOfFirstTransaction,
      indexOfLastTransaction
    );
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
    // Pagination Logic End

    return (
      <div className="table-responsive-md">
        <table className="table table-striped table-bordered table-hover table-light">
          <thead className="thead-light">
            <tr>
              <th>Transaction Hash</th>
              <th>Address</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {currentTransactions.map((transaction, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="trancated" title={transaction.hash}>
                      {transaction.hash}
                    </div>
                  </td>
                  <td>
                    <div className="trancated" title={transaction.from}>
                      {transaction.from}
                    </div>
                  </td>
                  <td>{transaction.value / 10 ** 18}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {allTransactions.length > 10 && (
          <div className="pagination-holder">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={allTransactions.length}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="relative bg-white py-5">
      <div className="container">
        {(!accounts || accounts.length === 0) && (
          <div className="text-center">
            <button className="btn btn-primary mb-5" onClick={props.onConnect}>
              {loading && <Spinner color="white" size={24} />}
              {!loading && (error !== "" ? error : "Connect Metamask Wallet")}
            </button>

            <Steps />
          </div>
        )}

        {accounts && accounts.length > 0 && (
          <>
            <div className="row justify-content-md-center">
              <div className="col-md-5 text-center">
                <Card title={genesisTokenName}>
                    <img
                      src={genesisTokenImage}
                      alt="Genesis FROSTY"
                      className="wide-img mb-3"
                    />
                    <p>{genesisTokenDescription}</p>

                    {new Date().getTime() / 1000 < genesisEndDate && (
                      <>
                        <div className="mt-3 text-center">
                          <strong>Sale ends on {genesisEndDateUtc}</strong>
                        </div>

                        <div className="mt-2 mb-3">
                          Minimum {minContribution} BNB / Maximum {maxContribution} BNB<br />                                                                              
                          Contributed with: <strong className='text-primary'>{contributedValue}</strong> BNB
                          
                          {genenisTokenId > -1 &&
                            <> / Token Id: <strong className='text-primary'>{genenisTokenId}</strong></>
                          }
                          
                          <br />
                          Remaining genesis: <strong className='text-primary'>{remainingGenesisTokens}</strong>
                        </div>

                        {contributedValue < maxContribution && (
                          <div className="row no-gutters mt-2">
                            <div className="col-lg-5">
                              <input
                                type="number"
                                maxLength="2"
                                min={minContribution}
                                max={maxContribution}
                                step="0.001"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                className="form-control"
                              />
                            </div>
                            <div className="col-lg-7">
                              <button
                                onClick={() => contribute()}
                                className="btn btn-primary btn-block"
                              >
                                {contributeLoading ? (
                                  <Spinner size={24} />
                                ) : (
                                  <span>CONTRIBUTE</span>
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {new Date().getTime() / 1000 > genesisEndDate && (
                      <>
                        <h4 className="mt-3 mb-2">The genesis sale is over</h4>
                        <div>Your contribution value: <strong className='text-primary'>{contributedValue} BNB</strong></div>
                        {genenisTokenId > -1 &&
                          <div>Your Genesis Token Id: <strong className='text-primary'>{genenisTokenId}</strong></div>
                        }
                      </>
                    )}
                </Card>
              </div>
            </div>

            {/* Transactions */}
            <div className="pt-5 px-0">
              {allTransactions ? <TransactionsList /> : <h5>LOADING</h5>}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GenesisSalePage;
