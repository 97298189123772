import React from "react";

const RoadmapTimeline = () => {
  return (
    <div className="timeline-bg py-5">
      <h2 className="text-center text-primary mb-4">Our Roadmap</h2>
      <div className="timeline-holder">
        <div className="px-5 pt-5">
          <div className="rb-container">
            <ul className="rb">
              <li className="timezone">
                <div className="timestamp">
                  October 2nd 11 PM UTC - October 20th 11PM UTC
                </div>
                <div className="title">
                  Genesis FRST NFT Distribution at https://www.frosty.app
                </div>
              </li>
              <li className="timezone">
                <div className="timestamp">October 20th</div>
                <div className="title">Genesis FRST NFT unlocked</div>
              </li>
              <li className="timezone">
                <div className="timestamp">November 1st</div>
                <div className="title">
                  Staking Mechanism, Introducing $FRST
                </div>
              </li>
              <li className="timezone">
                <div className="timestamp">Mid November</div>
                <div className="title">
                  Introducing Auction Marketplace on FROSTY Platform
                </div>
              </li>
              <li className="timezone">
                <div className="timestamp">Mid November</div>
                <div className="title">
                  Synthetic NFT Collateral Contracts Staking
                </div>
              </li>
              <li className="timezone current">
                <div className="timestamp">Q1 2022</div>
                <div className="title">FROSTY into the 3D verse</div>
              </li>
              <li className="timezone">
                <div className="timestamp">Q2 2022</div>
                <div className="title">Digital to Physical Art</div>
              </li>
              <li className="timezone">
                <div className="timestamp">Q3 2022</div>
                <div className="title">In-game NFT integration</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadmapTimeline;
