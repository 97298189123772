import React from "react";
import { Card } from "../components";

export default function Steps () {
    return (
        <Card title="Info">
            <div className="text-center">
                <ul>
                    <li>Login in to your MetaMask wallet</li>
                    <li>Make sure you have the BSC network selected</li>
                    <li>You can always reach us on our official telegram chat group <a href="https://t.me/official_frosty" target="_blank" rel="noopener noreferrer"><strong>@official_frosty</strong></a></li>                                 
                </ul>
            </div>
        </Card>
    );
};
